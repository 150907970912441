import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import itelogo from '../assets/images/ITE_Logo.png'
import sutdlogo from '../assets/images/sutd_logo.png'
import astarlogo from '../assets/images/astarlogo.png'


const Partners = props => (
  <Layout>
    <Helmet>
      <title>Our Partners</title>
      <meta name="description" content="Right Sidebar Page" />
    </Helmet>

    <div id="main" className="wrapper style1">
      <div className="container">
        <header className="major">
          <h2>Our Partners</h2>
          <p>
            Collaboration
          </p>
        </header>

        <section className="col-12">
            <div className="box alt" style={{backgroundColor:`#fafafc`, padding:`15px`}}>
              
              
              <br/>
              <div className="row gtr-150">
                <div className="col-4 col-12-medium">
                  <img src={itelogo} style={{
                          maxHeight: `250px`,
                          maxWidth: `100%`
                          
                        }}></img>
                        
                  <h3 style={{textAlign:`center`, color:`black`}}>ITE</h3>
                  <p style={{textAlign:`center`, color:`black`}}>
                    5-Year Collaboration in High-Tech Urban Farming
                  </p>
                </div>
                <div className="col-4 col-12-medium">
                  <img src={sutdlogo} style={{
                          maxHeight: `250px`,
                          maxWidth: `96%`
                          
                        }}></img>
                        
                  <h3 style={{textAlign:`center`, color:`black`}}>SUTD</h3>
                  <p style={{textAlign:`center`, color:`black`}}>
                    Capstone with Final Year Students
                  </p>
                </div>
                <div className="col-4 col-12-medium">
                  <img src={astarlogo} style={{
                          maxHeight: `250px`,
                          maxWidth: `100%`
                          
                        }}></img>
                        
                  <h3 style={{textAlign:`center`, color:`black`}}>A*STAR</h3>
                  <p style={{textAlign:`center`, color:`black`}}>
                    In partnership with SIMTech
                  </p>
                </div>
                
              </div>
            </div>
          </section>
      </div>
    </div>
  </Layout>
)

export default Partners
